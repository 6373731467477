body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.cdnfonts.com/css/aileron');

body {
    font-family: 'Aileron', sans-serif;
}

.jumbotron-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
  }
/*
#home-jumbotron {
    min-height: 320px;
    background-repeat: no-repeat;
  }*/

.carousel-content{
    padding: 0 10em 0 10em;
}

/* For devices lower than 576px */
@media (max-width: 575px) {
    /*#home-jumbotron {
        min-height: 150px;
        background-repeat: no-repeat;
    }*/

    .carousel-content{
        padding: 0 3em 0 3em;
    }
}
/* For devices larger than 992px */
/*@media (min-width: 992px) {
    #home-jumbotron {
        min-height: 500px;
        background-repeat: no-repeat;
    }
}*/

.footer {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
   }

.footerMask {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    width: 100%;
    height: 100%;
}

/* Style all font awesome icons */
.fa {
    padding: 20px;
    font-size: 30px;
    width: 50px;
    text-align: center;
    text-decoration: none;
  }

  /* For devices lower than 576px */
@media (max-width: 575px) {
  .fa{
    padding: 10px;
    font-size: 25px;
    width: 35px;
  }
}
  
  /* Add a hover effect if you want */
  .fa:hover {
    opacity: 0.7;
  }
  
  /* Set a specific color for each brand */
  
  /* Facebook */
  .fa-facebook {
    background: #3B5998;
    color: white;
  }
  
  /* Twitter */
  .fa-twitter {
    background: #55ACEE;
    color: white;
  }

  /* Instagram */
  .fa-instagram {
    background: #ac2bac;
    color: white;
  }

  /* email */
  .fa-envelope {
    background: #e05d05;
    color: white;
  }

.image-grid-container {
  display: grid;

  /* For 2 columns */
  grid-template-columns: auto auto;
}
.image-grid-container img {
    width: 100%;
}

